(function(wnd){
    function BlogSubscribeForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required!';
            }

            this.form = form;
            if (params) {
                params.setParams(params);
            }

            this.init();

        } catch (error) {
            console.error(error);
        }
    }

    BlogSubscribeForm.prototype = {
        button: undefined,
        form: undefined,
        sendedForm: false,
        notyf: undefined,
        formData: {
            u: 7,
            f: 7,
            s: '',
            c: 0,
            m: 0,
            act: 'sub',
            v: 2,
            sync: 1,
            overwrite: 0
        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find('[data-purpose="send-button"]');
            this.notyf = new Notyf({
                delay:5000,
            });
        },
        addDOMEvents: function() {
            var _self = this;

            this.button.click(function(){
                _self.sendForm();
            });
        },
        setParams: function(params) {
            console.log(params);
        },
        sendForm: function() {
            var _self = this;
            
            if (!this.sendedForm) {
                this.sendedForm = true;
                this.button.addClass('sended');
                var datas = $.extend({}, this.formData);
                
                this.form.serializeArray().forEach(function(field){
                    datas[field.name] = field.value;
                });
                
                $.ajax({
                    url:ajax_controller+'blog_subscribe',
                    dataType:'json',
                    type:'post',
                    data: datas,
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            if (typeof response.url !== 'undefined' && response.url) {
                                document.location.href = response.url;
                            } else {
                                _self.notyf.confirm(response.msg);
                                _self.resetForm();
                            }
                        } else {
                            _self.button.removeClass('sended');
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(key, value){
                                    var field = _self.form.find('[name="'+key+'"]');
                                    if (field.length > 0) {
                                        field.addClass('has-error');
                                    }

                                    if (value.trim() != '') {
                                        _self.notyf.alert(value);
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        resetForm: function() {
            this.form.find('.is-dirty').removeClass('is-dirty');
            this.form.find('.field').val('');
            this.form.find('[type="checkbox"]').prop('checked', false);
        }
    };

    wnd.BlogSubscribeForm = BlogSubscribeForm;
}) (window);
