(function (module) {
    function ActiveCampaign () {}

    ActiveCampaign.sendContactForm = function (fields) {
        var params = Object.assign({}, fields)

        params['u'] = 5;
        params['f'] = 5;
        params['c'] = 0;
        params['s'] = '';
        params['m'] = 0;
        params['act'] = 'sub';
        params['v'] = 2;
        params['jsonp'] = 'true';
        

        $.ajax({
            url: 'https://cromecommunication.activehosted.com/proc.php?' + $.param(params),
            method: 'GET',
            dataType: 'jsonp',
            jsonp: false,
            jsonpCallback: function(datas) {
                ActiveCampaign.setActiveCampaignId(fields.email);
                return false;
            },
            success: function(response) {
            }
        });
    }

    ActiveCampaign.setActiveCampaignId = function(email) {
        $.ajax({
            url: ajax_controller+'set_active_campaign_id',
            method: 'POST',
            dataType:'json',
            data: {
                email:email
            }
        });
    };

    ActiveCampaign.trackEvent = function(evt_name, data) {
        if (!evt_name) {
            return;
        }
        
        $.ajax({
            url:ajax_controller+'track_active_campaign_event',
            dataType:'json',
            type:'post',
            data: {
                name: evt_name,
                datas: data
            },
            success: function(resp) {}
        });
    }

    if (module && module.exports) {
        module.exports = ActiveCampaign;
    } else {
        module.ActiveCampaign = ActiveCampaign;

        module._show_thank_you = function() {
            return false;
        }

        module._show_error = function() {
            
        }
    }

    
})(window);
