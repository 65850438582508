(function(window){
    function CommentForm(parent, params) {
        try {
            this.parent = parent;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CommentForm.prototype = {
        parent: undefined,
        container: undefined,
        form:undefined,
        button: undefined,
        sendedForm: undefined,
        randNumber: undefined,
        main: false,
        parent_comment_id: 0,
        notyf: undefined,
        init: function() {
            this.randNumber = Math.floor((Math.random() * 10000) + 1);
            this.createContent();

            this.notyf = new Notyf({
                delay: 5000
            });

            this.addDOMEvents();
        },
        addDOMEvents: function() {
            var _self = this;

            this.container.find('.mdl-js-textfield').each(function(ind, el) {
                var th = $(el);
                var input = th.children();
                var label = th.data('label');
                if (label == undefined) {
                    label = input.attr('placeholder');
                    input.attr('placeholder', null);
                }
                var id = input.attr('id');
                if (id == undefined) {
                    id = input.attr('name');
                    input.attr('id', id);
                }
                var classes = 'mdl-textfield';
                th.addClass(classes);
                input.addClass('mdl-textfield__input');
        
                if (label != undefined) {
                    th.addClass('mdl-textfield--floating-label');
                    th.append('<label class="mdl-textfield__label" for="' + id + '">' + label + '</label>');
                }
            });

            this.button.click(function(){
                _self.sendForm();
            });
        },
        setParams: function(params) {
            if (typeof params.main !== 'undefined') this.setMain(params.main);
            if (params.parent_comment_id) this.setParentCommentId(params.parent_comment_id);
        },
        sendForm: function() {
            var _self = this;
            if (!this.sendedForm) {
                this.sendedForm = true;
                this.button.addClass('sended');

                var data = _self.form.serialize();
                data += '&parent_id='+this.getParentCommentId()+'&article_id='+this.parent.getArticleId();
                
                $.ajax({
                    url:_self.parent.getOption('formAction'),
                    type:'post',
                    dataType:'json',
                    data: data,
                    beforeSend: function() {
                        _self.container.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.resetForm();
                        } else {
                            _self.button.removeClass('sended');
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(key, value) {
                                    var item = _self.container.find('[name="'+key+'"]');
                                    if (item.length > 0) {
                                        item.addClass('has-error');
                                    }
                                    if (value.trim() != '') {
                                        _self.notyf.alert(value);
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        setMain: function(main) {
            this.main = main;
        },
        isMain: function() {
            return this.main;
        },
        setParentCommentId: function(parent_id) {
            this.parent_comment_id = parent_id;
        },
        getParentCommentId: function() {
            return this.parent_comment_id;
        },
        createContent: function() {
            this.container = $('<div>', {
                class:'comment-write-box form-content',
                id:'commentWriteBox'+this.randNumber
            });

            this.container.append('<div class="like-h2">'+this.parent.getText('commentBoxTitle')+'</div>');

            this.form = $('<form>', {
                id:'commentForm'+this.randNumber
            });

            this.button = $('<button>', {
                'class': 'button purple form-send-button',
                'type': 'button',
                'html': '<span class="text" data-label="'+this.parent.getText('cta_sent_text_label')+'"><span class="text-inner">'+this.parent.getText('comment_send_cta')+'</span></span>' 
            });

            var icon = this.parent.getOption('sendCtaIcon');
            if (icon) {
                this.button.append('<span class="icon-outer">'+svg(icon)+'</span>');
            }

            var topRow = $('<div>', {
                class:'two-col-row'
            });

            var fields = ['name', 'email'];

            for (var i = 0; i < fields.length; i++) {
                var row = $('<div>', {
                    class:'form-row mdl-js-textfield'
                });

                row.append($('<input>', {
                    type:'text',
                    class:'field',
                    placeholder:this.parent.getText(fields[i]+'_placeholder')+'*',
                    name:fields[i],
                    value: this.parent.getActiveCampaignData(fields[i])
                }));

                topRow.append(row);
            }

            topRow.append('<div class="clear"></div>');
            var textareaRow = $('<div>', {
                class:'form-row textarea-row mdl-js-textfield before-privacy',
                html:'<textarea name="comment" class="field" placeholder="'+this.parent.getText('comment_message_placeholder')+'*"></textarea>'
            });

            var privacyRow = $('<div>', {
                class:'form-row privacy-row'
            });

            privacyRow.append($('<div>', {
                'class':'link-content',
                'html':this.parent.getText('privacy_policy_chk_label')
            }));


            var buttonRow = $('<div>', {
                class:'form-row button-row',
                html:this.button
            });

            this.form.append(topRow);
            this.form.append(textareaRow);
            this.form.append(privacyRow);
            this.form.append(buttonRow);

            this.container.append(this.form);
        },
        getHtml: function() {
            return this.container;
        },
        resetForm: function() {
            var content = $('<div>', {
                class:"comment-thank-you"
            });

            content.append('<div class="like-h2">'+this.parent.getText('comment_thank_you_title')+'</div>');
            content.append($('<div>', {
                class:"comment-thank-you-lead",
                html: this.parent.getText('comment_thank_you_lead')
            }));
            this.container.html('');
            this.container.append(content);
        }
    };


    function CommentBox(container, params) {
        try {
            if (!container || (container && container.length == 0)) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CommentBox.prototype = {
        container: undefined,
        commentsContent: undefined,
        commentList: undefined,
        mainForm: undefined,
        selectors: {
            commentsContent: '[data-purpose="comments-content"]'
        },
        options: {
            childListAnimationSpeed:600,
        },
        texts: {
            commentBoxTitle: 'Hozzászólás'
        },
        article_id: undefined,
        comments: [],
        activeCampaignData: {},
        init: function() {
            this.createForm();
            this.setElements();
            this.createElements();
            this.writeComments();
            this.addDOMEvents();
        },
        createForm: function() {
            this.mainForm = new CommentForm(this, {
                main: true,
            });
            this.container.prepend(this.mainForm.getHtml());
        },
        setElements: function() {
            this.commentsContent = this.container.find(this.getSelector('commentsContent'));
        },
        createElements: function() {
            this.commentList = $('<ul>', {
                class:'comment-list'
            });

            this.commentsContent.append(this.commentList);
        },
        addDOMEvents: function() {
            var _self = this;

            this.container.find('.child-comment-list.has-more-answer').each(function(){
                var height = 0;
                var i = 0;
                $(this).find('li').each(function(){
                    if (i > 1) {
                        return false;
                    }

                    height += $(this).outerHeight(true);
                    i++;
                });

                $(this).height(height);
            });

            this.container.find('[data-purpose="add-comment-btn"]').click(function(){
                var parent_id = $(this).data('parent-id');
                var container = $('[data-purpose="child-comments-container"][data-parent-id="'+parent_id+'"]');
                if (container.length > 0) {
                    var list = container.find('[data-purpose="child-comments"]');
                    var currentForm = container.find('.form-list-item');
                    if (currentForm.length > 0) {
                        currentForm.remove();
                    }

                    var form = new CommentForm(_self, {
                        main:false,
                        parent_comment_id: parent_id
                    });

                    var item = $('<div>', {
                        'class':'form-list-item',
                        'html': form.getHtml()
                    });

                    container.append(item);
                    
                    refreshInputs();
                    var time = 10;
                    if (list.length > 0 && list.hasClass('has-more-answer') && !list.hasClass('opened')) {
                        var btn = _self.container.find('[data-purpose="list-open-btn"][data-parent-id="'+parent_id+'"]');
                        
                        if (btn.length > 0) {
                            // time = _self.getOption('childListAnimationSpeed');
                            _self.openChildList(btn);
                        }
                    }

                    var targetId = item.find('form').attr('id');
                    if (targetId) {
                        setTimeout(function(){
                            $('html, body').animate({
                                scrollTop: ($('#'+targetId).offset().top - 200),
                            }, 300);
                        }, time);
                    }
                }
            });

            this.container.find('[data-purpose="list-open-btn"]').click(function(){
                _self.openChildList($(this));
                
            });
        },
        openChildList: function(btn, withoutTransition) {
            var parent = btn.parents('[data-purpose="child-comments-container"]');
            if (parent.length > 0) {
                var list = parent.find('ul');
                if (list.length > 0) {
                    var height = 0;
                    list.find('li').each(function(){
                        height += $(this).outerHeight(true);
                    });
                    btn.parent().fadeOut(200, function(){
                        $(this).remove();
                    });

                    if (withoutTransition) {
                        list.addClass('without-transition');
                    }

                    list.height(height);
                    
                    setTimeout(function(){
                        list.addClass('opened');
                        list.removeAttr('style');
                    }, this.getOption('childListAnimationSpeed'));
                }   
            }
        },
        setParams: function(params) {
            if (params.selectors) this.setSelectors(params.selectors);
            if (params.texts) this.setTexts(params.texts);
            if (params.options) this.setOptions(params.options);
            if (params.article_id) this.setArticleId(params.article_id);
            if (params.comments) this.setComments(params.comments);
            if (params.activeCampaignData) this.setActiveCampaignData(params.activeCampaignData);
        },
        setActiveCampaignData: function(datas) {
            this.activeCampaignData = $.extend({},this.activeCampaignData,datas);
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setSelectors: function(selectors) {
            this.selectors = $.extend({}, this.selectors, selectors);
        },
        setOptions: function(options) {
            this.options = $.extend({}, this.options, options);
        },
        setArticleId: function(article_id) {
            this.article_id = article_id;
        },
        setComments: function(comments) {
            this.comments = comments;
        },
        getSelector: function(key) {
            return typeof this.selectors[key] !== 'undefined' ? this.selectors[key] : undefined;
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        getOption: function(key) {
            return typeof this.options !== 'undefined' ? this.options[key] : undefined;
        },
        getArticleId: function() {
            return this.article_id;
        },
        getActiveCampaignData: function(key) {
            if (key) {
                return typeof this.activeCampaignData[key] !== 'undefined' ? this.activeCampaignData[key] : '';
            }
            return this.activeCampaignData;
        },
        writeComments: function() {
            var _self = this;
            if (this.comments.length > 0) {
                for (var i = 0; i < this.comments.length; i++) {
                    var comment = this.comments[i];

                    var item = $('<li>', {
                        'data-comment-id':comment.comment_id,
                        html:_self.writeCommentListItem(comment)
                    });

                    var innerContent = $('<div>', {
                        class:'child-comments-outer',
                        "data-purpose":'child-comments-container',
                        'data-parent-id':comment.comment_id
                    });

                    var childList = $('<ul>', {
                        'class':'child-comment-list',
                        'data-purpose':'child-comments',
                        'data-parent-id':comment.comment_id
                    });

                    if (typeof comment.children !== 'undefined' && comment.children.length > 0) {
                        for (var j = 0; j < comment.children.length; j++) {
                            childList.append($('<li>', {
                                'data-parent-id':comment.comment_id,
                                'html':_self.writeCommentListItem(comment.children[j])
                            }));
                        }

                        if (comment.children.length > 2) {
                            childList.addClass('has-more-answer');

                            var openRow = $('<div>', {
                                class:'open-row',
                                html: $('<a>', {
                                    href:'javascript:void(0);',
                                    'data-purpose':'list-open-btn',
                                    html:this.getText('view_more_comments'),
                                    'data-parent-id':comment.comment_id
                                })
                            });

                            innerContent.append(openRow);

                        }
                    }

                    innerContent.prepend(childList);

                    item.append(innerContent);

                    this.commentList.append(item);
                }
            }
        },
        writeCommentListItem: function(comment) {
            var _self = this;
            
            var content = $('<div>', {
                'class':'comment-item '
            });

            var topRow = $('<div>', {
                class:'name-row',
                html:'<span class="name">'+comment.name+'</span><span class="date">'+comment.date+'</span>'
            });

            content.append(topRow);
            content.append('<div class="comment">'+comment.comment+'</div>');
            
            var buttonRow = $('<div>', {
                'class':'button-row',
                html: $('<a>', {
                    'href':'javascript:void(0)',
                    'data-purpose':'add-comment-btn',
                    'data-parent-id':(comment.parent_id == null ? comment.comment_id : comment.parent_id),
                    'html':_self.getText('add_comment_btn_txt')
                })
            });

            content.append(buttonRow);
            return content;
        }
    };

    window.CommentBox = CommentBox;
})(window);
