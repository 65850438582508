var windowWidth = 0;
var headerSlider = undefined;
var mainHeader = undefined;
var helloBar = undefined;
var acEvtData = {};

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	__createFormInputs();

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('.mailtowithicon').click(function(e) {
		if ($(this).attr('href') == 'javascript:void(0)') {
			$(this).attr('href', 'mailto:' + $(this).data('user') + '@' + $(this).data('domain'));
		}
	});

	$('[data-purpose="home-form-button"]').click(function(e){
		var checked = $.find('.tomb-type-radio:checked');
		if (checked.length > 0) {
			var href = $(this).attr('href');
			href += '?tombType='+$(checked).val();
			
			document.location = href;
			e.preventDefault();
			
		} else {
			e.preventDefault();
		}
	});


	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			$('[data-remodal-id="' + $(this).data('popup-target') + '"]').remodal().open();
		}
	});

	$('.home .radio-group input[type="radio"]').change(function(){
		var parent = $(this).parents('.radio-group');
		if (parent.length > 0) {
			var row = parent.parents('.form-content');
			if (!parent.hasClass('selected')) {
				var type = parent.data('type');
				if (type) {
					$('.radio-group[data-type="'+type+'"]').removeClass('selected');
					parent.addClass('selected');
				}
			}

			if (row.length > 0) {
				var url = row.data('form-url');
				document.location = url+'?tombType='+$(this).val();
			}
		}
	});

	if( $('.cookie-alert-outer').length > 0 ) {
		setTimeout(function(){
			var height = $('.cookie-alert-outer .content-width').outerHeight();
			$('.cookie-alert-outer').animate({'height':height+'px'}, 600);
		}, 550);

		var alertResize = debounce(function(){
			if(!$('.cookie-alert-outer').hasClass('closed')){
				var height = $('.cookie-alert-outer .content-width').outerHeight();
				$('.cookie-alert-outer').height(height);
			}
		}, 50);

		window.addEventListener('resize', alertResize);

		$('.close-cookie-alert').click(function(){
			$('.cookie-alert-outer').animate({'height':'0px'}, 600);
			$('.cookie-alert-outer').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		
		if (item.length > 0) {
			var headerTop = $('#wrapper > header').outerHeight();
			var top = $(this).offset().top;
			var targetTop = item.offset().top;
			
			var tmp = Math.abs(targetTop / top);
			
			$('html, body').animate({scrollTop: targetTop - headerTop}, tmp * 200);

			if (typeof site_id !== 'undefined' && site_id == 23) {
				item.find('[data-role="accordion-title"]').trigger('click');
			}
		}
	});

	$('[data-purpose="question-opener"]').click(function(){
		var article = $(this).parents('article');
		
		if (article.length == 1) {
			var desc = article.find('.description');
			if (desc.length > 0) {
				
				if (desc.hasClass('opened')) {
					desc.removeAttr('style');
					desc.removeClass('opened');
					article.find('[data-purpose="question-opener"]').removeClass('opened');
					article.find('[data-purpose="question-opener"] .close-text').hide();
					article.find('[data-purpose="question-opener"] .detail-text').show();
				} else {
					var inner = desc.find('.inner');
					var height = inner.outerHeight();
					
					desc.addClass('opened');
					desc.height(height);
					article.find('[data-purpose="question-opener"]').addClass('opened');
					article.find('[data-purpose="question-opener"] .close-text').show();
					article.find('[data-purpose="question-opener"] .detail-text').hide();
				}
			}
		}
	});

	$('[data-purpose="footer-social"]').click(function(){
		if (typeof ActiveCampaign !== 'undefined') {
			var evtData = $(this).data('eventdata');
			ActiveCampaign.trackEvent('footer_social', {
				eventdata: evtData
			});
		}
	});

	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			var header = $('header.home');
			var borderItem = $('#what-is-crome');
			
			var scrollFn = debounce(function(){	
				if (borderItem.length > 0 && header.length > 0) {
					var scrollTop = $(window).scrollTop();
					var offsetTop = borderItem.offset().top;
					
					if (scrollTop >= offsetTop && !header.hasClass('fixed')) {
						header.addClass('fixed');
						setTimeout(function(){	
							header.addClass('with-transition');
							header.addClass('opened');
						}, 10);
					} else if (scrollTop < offsetTop && header.hasClass('fixed')) {
						header.removeClass('opened');
						setTimeout(function(){
							header.removeClass('with-transition');
							header.removeClass('fixed');
						}, 200);
					}
				}
			}, 10);

			window.addEventListener('scroll', scrollFn);
			window.addEventListener('touchend', scrollFn);
			window.addEventListener('touchmove', scrollFn);

			var resizeFn = function(){
				if (windowWidth != $(window).width()){
					windowWidth = $(window).width();
					if (typeof headerSlider !== 'undefined') {
						headerSlider.destroySlider();
					}

					initSlider();
				}
			}
			
			window.addEventListener('resize', resizeFn);
			initSlider();
		} else if (site_id == 16) {
			$('[data-purpose="group-open-bar"]').click(function(){
				var parent = $(this).parents('[data-purpose="team-group"]');
				if (parent.length > 0) {
					if (parent.hasClass('opened')) {
						parent.removeAttr('style');
						parent.removeClass('opened');
					} else {
						var height = $(this).outerHeight(true);
						parent.find('.team-member-item').each(function(){
							height += $(this).outerHeight(true);
						});
						parent.addClass('opened');
						parent.height(height);
					}
				}
			}).first().click();

			var resizeFn = function() {
				if (windowWidth != $(window).width()){
					windowWidth = $(window).width();
					setTeamGroupHeight();
				}
			}

			window.addEventListener('resize', resizeFn);
		} else if (site_id == 21) {
			
			if (typeof current_article_id !== 'undefined' && typeof current_article_title !== 'undefined') {
				acEvtData.eventdata = current_article_id+'_'+current_article_title.substring(0, 10);
			}

			$('[data-purpose="url-share"]').click(function(){
				var currentUrl = window.location.href;
				var input = $('<input>', {
					type:'text',
					'value':currentUrl
				});
		
				$('body').append(input);
				
				input.select();
				document.execCommand('copy');
				input.remove();
				if (typeof ActiveCampaign !== 'undefined') {
					ActiveCampaign.trackEvent('article_share', acEvtData);
				}
			});

			$('[data-purpose="googleplus-share"]').click(function(){
				var href = $(this).data('url');
				socialShare('https://plus.google.com/share?url='+href);
				return false;
			});

			$('[data-purpose="linkedin-share"]').click(function(){
				var href = $(this).data('url');
				
				socialShare('https://www.linkedin.com/shareArticle?mini=true&url='+href);
				return false;
			});

			$('[data-purpose="facebook-share"]').click(function(){
				var href = $(this).data('url');
				
				if (typeof FB === 'object') {
					FB.ui({
						method:'share',
						href: href
					}, function(response) {
						if (typeof response === 'object' && (Object.keys(response)).length == 0 && typeof ActiveCampaign !== 'undefined') {
							ActiveCampaign.trackEvent('article_share', acEvtData)
						}
					});
				}
			});

			$('[data-purpose="mail-share"]').click(function(){
				if (typeof ActiveCampaign !== 'undefined') {
					ActiveCampaign.trackEvent('article_share', acEvtData);
				}
			});
		} else if (site_id == 19) {
			$('[data-purpose="reference-header-item"]').click(function() {
				if ($(this).hasClass('active')) {
					return false;
				}
				
				var itemId = $(this).data('reference-id');
				if (itemId) {
					$('[data-purpose="reference-header-item"]').removeClass('active');
					$('[data-purpose="reference-content"]').removeClass('active');
		
					$(this).addClass('active');
					$('[data-purpose="reference-content"][data-reference-id="'+itemId+'"]').addClass('active');
				}
			});
			
			$('[data-purpose="reference-header-item"].selected').trigger('click');

			var resizeFn = function(){
				if (windowWidth != $(window).width()){
					windowWidth = $(window).width();
					
					var width = 0;
					$('[data-purpose="reference-header-item"]').each(function(){
						width += $(this).outerWidth(true);
					});

					if (width < windowWidth) {
						width = '100%';
					} else {
						width += 'px';
					}
					$('#referenceHeaderInner').css('min-width', width);
				}
			}

			var headerWidth = 0;
			$('[data-purpose="reference-header-item"]').each(function(){
				headerWidth += $(this).outerWidth(true);
			});

			if (headerWidth < $(window).width()) {
				headerWidth = '100%';
			} else {
				headerWidth += 'px';
			}

			$('#referenceHeaderInner').css('min-width', headerWidth);

			window.addEventListener('resize', resizeFn);
		} else if (site_id == 17) {

			$('a.gallery-image-item').click(function(){
				if (typeof historyGalleryItems !== 'undefined') {
					var index = $(this).data('index');
					if (!index) {
						index = 0;
					}
					
					$(this).lightGallery({
						dynamic:true,
						dynamicEl:historyGalleryItems,
						index:index,
						hash:false,
						download: false
					});
				}
			});
		}
	}

	if ( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		$('[data-button-type="mobile-phone-button"]').click(function(){
			if (typeof ActiveCampaign !== 'undefined') { 
				ActiveCampaign.trackEvent('phone_click_mob');
			}
		});
	} 

	$('[data-button-type="blog-page-cta"]').click(function(){
		if (typeof ActiveCampaign !== 'undefined') { 
			ActiveCampaign.trackEvent('article_cta');
		}
	});

	$('.privacy-type-page .accordion-list').each(function(ind, el){
		var options = {};
		var th = $(el);
		
		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		
		th.find('table').each(function(){
			var container = $('<div>', {
				class:'privacy-table-outer'
			});

			container.insertBefore($(this));
			container.append($(this));
		});

		new Accordion(th, options);
	});

	if ($('[data-purpose="hello-bar"]').length > 0) {
		mainHeader = $('#wrapper > header');
		helloBar = $('[data-purpose="hello-bar"]');
		$('[data-purpose="hello-bar-close"]').click(function(){
			closeHelloBarContent(true);
		});
		$('[data-purpose="hello-bar-btn"]').click(function(){
			var slideUp = $(this).attr('href') == 'javascript:void(0)' ? true : false;
			closeHelloBarContent(slideUp);
		});

		var helloResize = function() {
			checkHelloBarPosition();
		};

		window.addEventListener('resize', helloResize);
		checkHelloBarPosition();
	}
});

function checkHelloBarPosition() {
	if (mainHeader && !mainHeader.hasClass('closed-hello-bar') && helloBar) {
		var paddingTop = 0;
		var isMobile = mainHeader.find('.mobile-header').is(':visible');
		for (var i = 0; i < helloBar.length; i++) {
			if ($(helloBar[i]).is(':visible')) {
				paddingTop = $(helloBar[i]).outerHeight(true);
				break;
			}
		}
		
		var baseHeight = isMobile ? mainHeader.find('.mobile-header > .inner').outerHeight(true) : mainHeader.find('> .top > .inner').outerHeight(true);
		var objPaddingTop = 0;
		if (isMobile || !mainHeader.hasClass('home')) {
			objPaddingTop = paddingTop+baseHeight;
		} else {
			objPaddingTop = paddingTop;
		}

		mainHeader.css({
			'padding-top': objPaddingTop
		});

		if (mainHeader.hasClass('home')) {
			mainHeader.find('.mobile-header').css({
				'padding-top':paddingTop
			});

			mainHeader.find('> .top').css({
				'padding-top':paddingTop
			});
		}
	}
}

function closeHelloBarContent(slideUp) {
	if (slideUp) {
		mainHeader.addClass('closed-hello-bar');
		helloBar.slideUp(250);
		mainHeader.removeAttr('style');
		if (mainHeader.hasClass('home')) {
			mainHeader.find('.mobile-header').removeAttr('style');
			mainHeader.find('> .top').removeAttr('style');
		}
	}

	var notification_id = helloBar.data('notification-id');
	
	$.ajax({
		url:ajax_controller+'closeHelloBar',
		dataType:'json',
		type:'post',
		data: {
			notification_id: notification_id
		}
	});
}

function socialShare(url)
{
	if (typeof ActiveCampaign !== 'undefined') {
		ActiveCampaign.trackEvent('article_share', acEvtData);
	}
	window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
	return false;
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag){
	if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}


function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function createWindowSizeBox() {
	$('#windowSizeBox').remove();

	var content = $('<div>', {
		'id': 'windowSizeBox',
		'html': '<hr>szélesség: <strong>'+$(window).width()+'</strong><br />magasság: <strong>'+$(window).height()+'</strong><br/>SEO robots content: <strong>'+seoRobotsContent+'</strong><br/><br/>'
	});

	$('body').append(content);
}

function __createFormInputs() {
	$('.mdl-js-textfield').each(function(ind, el) {
		var th = $(el);
		
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('placeholder');
			input.attr('placeholder', null);
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name');
			input.attr('id', id);
		}
		var classes = 'mdl-textfield';
		th.addClass(classes);
		input.addClass('mdl-textfield__input');

		if (label != undefined) {
			th.addClass('mdl-textfield--floating-label');
			th.append('<label class="mdl-textfield__label" for="' + id + '">' + label + '</label>');
		}

	});

	$('.mdl-checkbox').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-checkbox mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-checkbox__input');

		th.append('<span class="mdl-checkbox__label">' + label + '</span>');

	});

	$('.mdl-radio').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		var error = th.data('error');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-radio mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-radio__button');

		th.append('<span class="mdl-radio__label">' + label + '</span>');

		if (error) {
			th.append('<span class="mdl-textfield__error">' + error + '</span>');
		}

	});

	$('.custom-select:not(".with-button")').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.children('.mdl-js-textfield');
		var defaultValue = '';

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			if (!ind2) defaultValue = th2.text();
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');

		label.click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			label.addClass('is-dirty');
			th.toggleClass('active');
			th.find('.mdl-menu__container').toggleClass('is-visible');
		});

		th.find('li').click(function() {
			var sel = $(this).text();
			label.removeClass('is-invalid').children('input').val(sel);
			options.val(sel);
			if (!options.val()) label.removeClass('is-dirty');
		});

		label.find('input').val(defaultValue);

	});

	$('.custom-select.with-button').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.data('label');
		var url = th.data('url');

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');
		th.append('<a class="button orange-button" href="javascript:void(0)" id="' + id + '">' + label + '<span class="sprite mainSprite-whiteSelectArrow"></span></a>');

		th.find('.button').click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			$(this).toggleClass('clicked');
		});

		th.find('li').click(function() {
			var sel = $(this).data('value');
			options.val(sel);

			if (url != undefined) {
				window.location = url + sel;
			}
		});

	});

	refreshInputs();

	if ($('[data-purpose="scroll-guide"]').length > 0) {
		var scrollGuidCheck = function() {
			if ($(window).scrollTop() >= 100) {
				var item = $('[data-purpose="scroll-guide"]');
				if (item.length > 0 && !item.hasClass('handled')) {
					item.addClass('handled');
					item.fadeOut(400, function(){
						item.remove();
					});

					window.removeEventListener('scroll', scrollGuidCheck);
					window.removeEventListener('touchend', scrollGuidCheck);
					window.removeEventListener('touchmove', scrollGuidCheck);
				}
			}
		};

		window.addEventListener('scroll', scrollGuidCheck);
		window.addEventListener('touchend', scrollGuidCheck);
		window.addEventListener('touchmove', scrollGuidCheck);
	}
	
}

function refreshInputs() {
	componentHandler.upgradeAllRegistered();
}

function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		var subject = hidden.data('subject');
		
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain') + (subject && subject.trim() != '' ? '?subject='+subject : ''));
	}
}

function googleMapsLoaded()
{
	$(document).trigger('pwcms.googlemapsloaded');
}

function addScript(file, callback) {
	var script = document.createElement('script');
    if (callback) {
		script.onload = callback;
	} 

    script.type = 'text/javascript';
    script.src = file;
    document.body.appendChild(script);  
}


function initSlider()
{
	var touchDevice = false;
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
		touchDevice = true;
	}
	headerSlider = $('#headerSlider').bxSlider({
		auto:true,
		pause:20000,
		infiniteLoop: true,
		touchEnabled: touchDevice,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
	});
}

function setTeamGroupHeight()
{
	$('.team-group-outer').each(function(){
		var height = $(this).find('[data-purpose="group-open-bar"]').outerHeight(true);
		if ($(this).hasClass('opened')) {
			$(this).find('.team-member-item').each(function(){
				height += $(this).outerHeight(true);
			});
		}

		$(this).height(height);
	});
}

function getMapStyle()
{
	return [
		{
			"featureType": "all",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"saturation": 36
				},
				{
					"color": "#333333"
				},
				{
					"lightness": 40
				}
			]
		},
		{
			"featureType": "all",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"featureType": "all",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 17
				},
				{
					"weight": 1.2
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f8f8f8"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#dedede"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 29
				},
				{
					"weight": 0.2
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#97bc75"
				},
				{
					"lightness": "0"
				},
				{
					"saturation": "0"
				},
				{
					"gamma": "2.54"
				},
				{
					"weight": "0"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"lightness": "-73"
				},
				{
					"invert_lightness": true
				},
				{
					"saturation": "-65"
				},
				{
					"visibility": "on"
				},
				{
					"hue": "#ff0000"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f2f2f2"
				},
				{
					"lightness": 19
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e9e9e9"
				},
				{
					"lightness": 17
				}
			]
		}
	];
}
