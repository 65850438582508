(function(window){
    function ContactPage(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }
            
            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ContactPage.prototype = {
        container: undefined,
        form: undefined,
        map: undefined, 
        marker: undefined,
        options: {
            characterLimit: 5000
        },
        navigationProtocols: {
            google: 'https://maps.google.com/maps',
            apple: 'maps://'
        },
        sendedForm: false,
        notyf: undefined,
        source_page_id: undefined,
        mapCenter: [19.052390, 47.464174],
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.form = this.container.find('#contactForm');
            this.notyf = new Notyf({
                delay:5000
            });
        },
        addDOMEvents: function() {
            var _self = this;

            var charLimit = this.getOption('characterLimit');
            if (!isNaN(charLimit)) {
                this.form.find('[data-purpose="field-with-counter"]').keyup(function(e){
                    _self.setCounterField($(this));
                });
            }

            $(document).on('pwcms.googlemapsloaded', function(){
                _self.initMap();
            });

            this.container.find('[data-purpose="address"]').each(function(){
                _self.setNavigationUrl($(this));
            });

            this.container.find('button.primary').click(function () {
                _self.sendContactForm($(this));
            });
        },
        setParams: function(params) {
            if (params.source_page_id) this.setSourcePageId(params.source_page_id);
            if (params.map_center) this.setMapCenter(params.map_center);
        },
        setCounterField: function(input) {
            var counterField = $('[data-purpose="character-counter"][data-target="'+input.attr('id')+'"]');
            if (counterField.length > 0) {
                var value = input.val();
                counterField.html(value.length + ' / '+ this.getOption('characterLimit'));
            }
        },
        setMapCenter: function(center) {
            this.mapCenter = center;
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : undefined;
        },
        setNavigationUrl: function(item) {
            var address = item.data('address');
            
            if (address) {
                var protocol = 'google';
                var ua = navigator.userAgent.toLowerCase();
                var plat = navigator.platform;
                var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

                if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
                    function iOSversion() {
                            
                        if (/iP(hone|od|ad)/.test(navigator.platform)) {
                          var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                        }
                    }

                    var ver = iOSversion() || [0];
                    if (ver[0] >= 6) {
                        protocol = 'apple';
                    }
                }
                
                var urlSuffix = '/?daddr='+address;

                item.attr('target', '_blank');
                item.attr('href', this.navigationProtocols[protocol]+urlSuffix);
            }
        },
        initMap: function() {
            var _self = this;
            
            mapboxgl.accessToken = 'pk.eyJ1Ijoic2VydWthIiwiYSI6ImNqNmhxbjF2ejA5YXAzMnRlN3o4ZjBhdTQifQ.Wj8inzuV9QHNCovlvPucSg';
            this.map = new mapboxgl.Map({
                container: 'contactMap',
                style: 'mapbox://styles/seruka/cjepuixvvgyml2rpdtkaqmclh',
                center: _self.mapCenter,
                zoom: 14.5
            });

            this.map.on('load', function(){
                var el = document.createElement('div');
                el.className = 'map-marker';
                el.innerHTML = '<span class="marker-outer">'+svg('crome-logo')+'</span>';

                new mapboxgl.Marker(el)
                .setLngLat(_self.mapCenter)
                .addTo(_self.map);
            });

            this.map.scrollZoom.disable();
        },
        sendContactForm: function (btn) {
            var _self = this;
            
            if (!this.sendedForm) {
                this.sendedForm = true;
                btn.addClass('sended');
                var data = {
                    u: 5,
                    f: 5,
                    c: 0,
                    s: '',
                    m: 0,
                    act: 'sub',
                    v: 2,
                    sync: 1
                };

                this.form.serializeArray().forEach(function(field){
                    data[field.name] = field.value
                });

                if (typeof currentPersona !== 'undefined') {
                    data['field[1]'] = currentPersona;
                }

                if (this.source_page_id) {
                    data.source_page_id = this.source_page_id;
                }

                $.ajax({
                    url: ajax_controller+'send_contact_form',
                    method: 'post',
                    dataType:'json',
                    data: data,
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            if (typeof response.url !== 'undefined' && response.url != '') {
                                document.location.href = response.url;
                            } else if (typeof response.msg !== 'undefined') {
                                _self.notyf.confirm(response.msg);
                            }
                            _self.resetForm();
                        } else {
                            btn.removeClass('sended');
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(key, value){
                                    var field = _self.form.find('[name="'+key+'"]');
                                    if (field.length > 0) {
                                        field.addClass('has-error');
                                    }

                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        resetForm: function() {
            this.form.find('.is-dirty').removeClass('is-dirty');
            this.form.find('.field').val('');
            this.form.find('[type="checkbox"]').prop('checked', false);

            var charLimit = this.getOption('characterLimit');
            if (!isNaN(charLimit)) {
                var field = this.form.find('[data-purpose="field-with-counter"]');
                if (field.length > 0) {
                    this.setCounterField(field);
                }
            }
        },
        setSourcePageId: function(id) {
            if (!isNaN(id)) {
                this.source_page_id = id;
            }
        }
    };

    window.ContactPage = ContactPage;
})(window);
