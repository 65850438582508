(function(window){
    function CromeForm(form, params) {
        try {
            if (!form || (form && form.length == 0)) {
                throw 'Form is required'
            } else if (!form.is('form')) {
                throw 'The first parameter must be a form html element!';
            }
            
            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CromeForm.prototype = {
        form:undefined,
        activeCampaignFormId:undefined,
        extraFields: {
            9: {
                u: 9,
                f: 9,
                s: '',
                c: 0,
                m: 0,
                act: 'sub',
                v: 2
            },
            11: {
                u: 11,
                f: 11,
                s: '',
                c: 0,
                m: 0,
                act: 'sub',
                v: 2
            },
            13: {
                u: 13,
                f: 13,
                s: '',
                c: 0,
                m: 0,
                act: 'sub',
                v: 2
            },
            17: {
                u: 17,
                f: 17,
                s: '',
                c: 0,
                m: 0,
                act: 'sub',
                v: 2
            }
        },
        notyf: undefined,
        sendedForm: false,
        options: {},
        source_page_url: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.notyf = new Notyf({
                delay:5000
            });

            if (!this.source_page_url) {
                this.source_page_url = window.location.href.split('?')[0];
            }
        },
        addDOMEvents: function() {
            var _self = this;

            this.form.find('[data-purpose="send-form-btn"]').click(function(){
                _self.sendForm($(this));
            });

            if (this.form.find('select').length > 0) {
                this.form.find('select').change(function(){
                    if ($(this).val()) {
                        $(this).addClass('has-value');
                    } else {
                        $(this).removeClass('has-value');
                    }
                });
            }

            var charLimit = this.getOption('characterLimit');
            if (!isNaN(charLimit)) {
                this.form.find('[data-purpose="field-with-counter"]').keyup(function(e){
                    _self.setCounterField($(this));
                });
            }
        },
        setCounterField: function(input) {
            var counterField = $('[data-purpose="character-counter"][data-target="'+input.attr('id')+'"]');
            if (counterField.length > 0) {
                var value = input.val();
                counterField.html(value.length + ' / '+ this.getOption('characterLimit'));
            }
        },
        setParams: function(params) {
            if (params.activeCampaignFormId) this.setActiveCampaignFormId(params.activeCampaignFormId);
            if (params.extraFields) this.addExtraFields(params.extraFields);
            if (params.options) this.setOptions(params.options);
            if (params.sourcePageUrl) this.setSourcePageUrl(params.source_page_url);
        },
        setActiveCampaignFormId: function(id) {
            if (typeof this.extraFields[id] == 'undefined') {
                throw 'Unknown form id!';
            }

            this.activeCampaignFormId = id;
        },
        addExtraFields: function(fields) {
            if (this.activeCampaignFormId) {
                this.extraFields[this.activeCampaignFormId] = $.extend({}, this.extraFields[this.activeCampaignFormId], fields);
            } else {
                this.extraFields = $.extend({}, this.extraFields, fields);
            }
        },
        sendForm: function(btn) {
            var _self = this;
            if (!this.sendedForm) {
                btn.addClass('sended');
                _self.sendedForm = true;
                var datas = {};

                this.form.serializeArray().forEach(function(field){
                    datas[field.name] = field.value;
                });

                datas.source_page_url = this.source_page_url;
                datas.source_page_id = typeof site_id !== 'undefined' ? site_id : false;

                datas = $.extend({}, datas, _self.getExtraFields());
                
                $.ajax({
                    url: this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data:datas,
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.resetForm();
                            var hasUrl = false;
                            if (typeof response.url !== 'undefined' && response.url != '') {
                                hasUrl = true;
                                document.location.href = response.url;
                            } else if (typeof response.msg !== 'undefined') {
                                _self.notyf.confirm(response.msg);
                            }

                            if (!hasUrl) {
                                btn.removeClass('sended');
                            }
                        } else {
                            btn.removeClass('sended');
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(key, value){
                                    var field = _self.form.find('[name="'+key+'"]');
                                    if (field.length > 0) {
                                        if (field.is('select')) {
                                            field.parent().addClass('has-error');
                                        } else {
                                            field.addClass('has-error');
                                        }
                                    }

                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }
                                });
                            }
                        }
                    }, 
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        getExtraFields: function() {
            if (this.activeCampaignFormId) {
                return this.extraFields[this.activeCampaignFormId];
            }

            return {};
        },
        resetForm: function() {
            this.form.find('.is-dirty').removeClass('is-dirty');
            this.form.find('.field').val('');
            this.form.find('[type="checkbox"]').prop('checked', false);
            this.form.find('select').val('').change();

            var charLimit = this.getOption('characterLimit');
            if (!isNaN(charLimit)) {
                var field = this.form.find('[data-purpose="field-with-counter"]');
                if (field.length > 0) {
                    this.setCounterField(field);
                }
            }
        },
        setOptions: function(options) {
            this.options = $.extend({}, this.options, options);
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : undefined;
        },
        setSourcePageUrl: function(url) {
            this.source_page_url = url;
        }
    };

    window.CromeForm = CromeForm;
})(window);
