(function(wnd){
    function GDPRForm(container, params) {
        try {
            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    GDPRForm.prototype = {
        container: undefined,
        form: undefined,
        button: undefined,
        sendedForm: false,
        notyf: undefined,
        feedbackBar: undefined,
        bottomBorder: undefined,
        windowWith: 0,
        calendlyUrl: {
            hu: 'https://calendly.com/crome/gdpr',
            en: 'https://calendly.com/crome/gdpr-en'
        },
        firstStepContainer: undefined,
        secondStepContainer: undefined,
        checkboxFieldNames: ['activity', 'sale', 'partner', 'marketing', 'services'],
        init: function() {
            this.setElements();
            this.addDOMEvents();
            this.setFeedbackBar();

            console.log(this.getCalendlyUrl());
        },
        setElements: function(){
            this.firstStepContainer = this.container.find('[data-purpose="first-step"]');
            this.secondStepContainer = this.container.find('[data-purpose="second-step"]');
            this.form = this.firstStepContainer.find('form');
            this.button = this.form.find('[data-purpose="send-form-btn"]');
            this.feedbackBar = $('[data-purpose="scroll-feedback-bar"]');
            this.bottomBorder = this.container.find('[data-purpose="scroll-feedback-border"]');
            this.notyf = new Notyf({
                delay:5000
            });
        },
        addDOMEvents: function() {
            var _self = this;
            this.windowWidth = $(window).width();
            this.button.click(function() {
                _self.sendForm();
            });

            this.form.find('[data-purpose="has-additional"]').change(function(){
                _self.checkAdditionalContent($(this));
            });

            this.form.find('input[type="radio"], input[type="checkbox"]').change(function(){
                if ($(this).hasClass('has-error')) {
                    var checked = $(this).prop('checked');
                    if (checked) {
                        _self.form.find('[name="'+$(this).attr('name')+'"]').removeClass('has-error');
                    }
                }
            });
            
            var scrollFn = function() {
                _self.setFeedbackBar();
            };

            var resizeFn = function() {
                if (_self.windowWidth !== $(window).width()) {
                    _self.windowWidth = $(window).width();
                    _self.setFeedbackBar();
                }
            }

            window.addEventListener('scroll', scrollFn);
            window.addEventListener('touchend', scrollFn);
            window.addEventListener('touchmove', scrollFn);
            
            window.addEventListener('resize', resizeFn);
        },
        checkAdditionalContent: function(item) {
            var _self = this;
            var contents = this.container.find('[data-purpose="additional-content"][data-type="'+item.data('type')+'"]');
            if (contents.length > 0) {
                var show = true;
                var content = undefined;
                
                
                if (item.is(':radio')) {
                    content = contents;
                    var exptectValues = content.data('expect-values')+'';
                    if (exptectValues) {
                        exptectValues = exptectValues.split(',');
                        show = exptectValues.indexOf(item.val()) >= 0;
                    } else {
                        show = item.val() == 1;
                    }

                } else if (item.is(':checkbox')) {
                    $.each(contents, function(index, obj){
                        var elem = $(obj);
                        var exptectValues = elem.data('expect-values')+'';
                        if (exptectValues) {
                            exptectValues = exptectValues.split(',');
                            if (exptectValues.indexOf(item.val()) >= 0) {
                                content = elem;
                                if (item.prop('checked')) {
                                    show = true;
                                } else {
                                    show = false;
                                    _self.container.find('[data-purpose="has-additional"][data-type="'+item.data('type')+'"]').each(function(){
                                        if ($(this).prop('checked')) {
                                            if (exptectValues.indexOf($(this).val()) >= 0) {
                                                show = true;
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                } else {
                    console.log('más');
                }

                if (content) {
                    if (show && !content.is(':visible')) {
                        content.show();
                    } else if (!show && content.is(':visible')) {
                        content.hide();
                    }
                }
            }
        },
        setParams: function(params) {
            
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                // this.sendedForm = true;
                this.button.addClass("sended");

                var datas = {
                    u: 17,
                    f: 17,
                    s: '',
                    c: 0,
                    m: 0,
                    act: 'sub',
                    v: 2
                }
                
                this.form.serializeArray().forEach(function(field){
                    var name = field.name;
                    var index = name.indexOf('[]');
                    
                    if (index >= 0) {
                        name = name.substring(0, index);
                        if (typeof datas[name] == 'undefined') {
                            datas[name] = [];
                        }
                        datas[name].push(field.value);
                    } else {
                        datas[field.name] = field.value;
                    }
                });

                $.ajax({
                    url:this.form.attr('action'),
                    type:'post',
                    dataType:'json',
                    data:datas,
                    success: function(response) {
                        if (response.success) {
                            _self.notyf.confirm(response.msg);
                            _self.button.removeClass('sended');
                            _self.showSecondStep();
                        } else {
                            _self.button.removeClass('sended');
                            if (typeof response.errors === 'object') {
                                $.each(response.errors, function(name, msg) {
                                    if (msg.trim() != '') {
                                        _self.notyf.alert(msg);
                                    }
                                    if (_self.checkboxFieldNames.indexOf(name) >= 0) {
                                        name += '[]';
                                    }
                                    var field = _self.form.find('[name="'+name+'"]');
                                    if (field.length > 0) {
                                        field.addClass('has-error');
                                    }
                                });

                                var first = _self.form.find('.has-error').first();
                                if (first.length == 1) {
                                    var target = first;
                                    if (first.is(':radio') || first.is(':checkbox')) {
                                        target = _self.container.find('[for="'+first.attr('id')+'"]');
                                    }

                                    $('html,body').animate({
                                        scrollTop: (target.offset().top - 100)
                                    }, 500);
                                }
                            }
                        }
                    }
                });
            }   
        },
        setFeedbackBar: function() {
            if (this.bottomBorder && this.bottomBorder.length == 1) {
                var height = this.bottomBorder.offset().top + this.bottomBorder.outerHeight();       
            } else {
                var height = this.container.height() + this.container.offset().top;
            }

            var windowHeight = $(window).height();
            var width = 0;
            if (windowHeight < height) {
                var scrollTop = $(window).scrollTop();
                var currentBottom = scrollTop+windowHeight;
                var maxBottom = height;
                if (currentBottom < maxBottom) {
                    width = currentBottom / maxBottom * 100; 
                } else {
                    width = 100;
                }
            }

            this.feedbackBar.find('.bg-content').width(width+"%");
        },
        showSecondStep: function() {
            var _self = this;
            this.firstStepContainer.hide();
            this.secondStepContainer.find('.form-content').append($('<div>', {
                'class': 'calendly-inline-widget',
                'data-url': _self.getCalendlyUrl(),
                'style':'min-width:320px;height:580px;'
            }));
            this.secondStepContainer.find('.form-content').append($('<script>', {
                'type': 'text/javascript',
                'src': 'https://assets.calendly.com/assets/external/widget.js'
            }));

            this.secondStepContainer.show();
        },
        getCalendlyUrl: function() {
            return typeof frontend_lang !== 'undefined' && typeof this.calendlyUrl[frontend_lang] !== 'undefined' ? this.calendlyUrl[frontend_lang] : this.calendlyUrl.hus;
        }

    };

    window.GDPRForm = GDPRForm;
})(window);
