(function(window){
	function BlogList(container, params) {
		try {
			if (!container || (container && container.length == 0)) {
				throw 'Container is required';
			}

			this.container = container;

			if (params) {
				this.setParams(params);
			}

			this.init();
		} catch (error) {
			console.error(error);
		}
	}

	BlogList.prototype = {
		container:undefined,
		articlesContainer:undefined,
		button: undefined,
		current_page:0,
		counter:0,
		sended: false,
		ajax_url: 'ajax/get_blog_page',
		shareContent: undefined,
		init: function() {
			this.setElements();
			this.addDOMEvents();
		},
		setElements: function() {
			this.articlesContainer = this.container.find('[data-purpose="articles-list"]');
			this.button = this.container.find('[data-purpose="load-more-article-btn"]');
			this.shareContent = $('[data-purpose="share-content"]');
		},
		addDOMEvents: function() {
			var _self = this;

			if (this.button.length > 0) {
				this.button.click(function(){
					_self.current_page++;
					_self.getPage();
				});
			}

			if (this.shareContent && this.shareContent.length > 0) {
				var scrollFn = function() {
					if ($(window).width() > 767) {
						_self.checkShareContentPosition();
					} else {
						_self.checkShareContentMobilePosition();
					}
				};

				window.addEventListener('scroll', scrollFn);
				window.addEventListener('touchend', scrollFn);
				window.addEventListener('touchmove', scrollFn);
			}

			$('[data-purpose="blog-subscription-form"]').each(function(){
                new BlogSubscribeForm($(this));
            });
		},
		checkShareContentPosition: function() {
			var item = $('[data-purpose="share-content-border"]');
			var scrollTop = $(window).scrollTop();
			var topBorder = item.offset().top;

			var header = $('header.blog-page .top');
			if ($('header.blog-page .mobile-header').is(':visible')) {
				header = $('header.blog-page .mobile-header');
			}
			
			var headerHeight = header.outerHeight();
			
			if (scrollTop + headerHeight >= topBorder && !this.shareContent.hasClass('fixed')) {
				this.shareContent.addClass('fixed');
			} else if (topBorder > scrollTop + headerHeight && this.shareContent.hasClass('fixed')) {
				this.shareContent.removeClass('fixed');
			}
		},
		checkShareContentMobilePosition: function() {
			var scrollTop = $(window).scrollTop();
			var windowHeight = $(window).height();
			var header = $('header.blog-page .top');
			if ($('header.blog-page .mobile-header').is(':visible')) {
				header = $('header.blog-page .mobile-header');
			}
			var item = $('main.blog-page');
			var bottomBorder = item.offset().top + item.outerHeight(true) + header.outerHeight(true);

			if (scrollTop + windowHeight >= bottomBorder && !this.shareContent.hasClass('hidden')) {
				this.shareContent.addClass('hidden');
			} else if (scrollTop + windowHeight < bottomBorder && this.shareContent.hasClass('hidden')) {
				this.shareContent.removeClass('hidden');
			}
		},
		setParams: function(params) {
			if (params.counter) this.setCounter(params.counter);
			if (params.ajax_url) this.setAjaxUrl(params.ajax_url);
		},
		setCounter: function(counter) {
			this.counter = counter;
		},
		getCounter: function() {
			return this.counter;
		},
		setAjaxUrl: function(url) {
			this.ajax_url = url;
		},
		getAjaxUrl: function() {
			return this.ajax_url;
		},
		getCurrentPage: function() {
			return this.current_page;
		},
		getPage: function() {
			var _self = this;
			if (!this.sended) {
				this.sended = true;
				$.ajax({
					url:_self.getAjaxUrl(),
					type:'post',
					dataType:'json',
					data: {
						page: _self.getCurrentPage(),
					},
					success: function(response) {
						if (response.success) {
							_self.articlesContainer.append(response.html);
							var end = (_self.getCurrentPage() * 5) + 3 >= _self.getCounter();
							if (end) {
								_self.button.parent().fadeOut(300);
							}
						} 
					},
					complete: function() {
						setTimeout(function(){
							_self.sended = false;
						}, 500);
					}
				});
			}
		}
	};


	window.BlogList = BlogList;
})(window);
