(function(window){
    function BlogPage(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }
            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    BlogPage.prototype = {
        container: undefined,
        feedbackBar: undefined,
        ratingContent: undefined,
        ratingSelect: undefined,
        rate_sum: 0,
        rate_count: 0,
        article_id: undefined,
        article_title: undefined,
        bottomBorder: undefined,
        rated: false,
        windowWidth: 0,
        ratedArticles: [],
        voteAfterArticles: [],
        voteBox:undefined,
        voted: false,
        sendedVote: false,
        shareContent: undefined,
        sendedEndScrollEvt: false,
        popupSubscribeBreakpoint: 600,
        articlePersonas: [],
        init: function() {
            this.setElements();
            this.addDOMEvents();
            this.setRatingBar();
            this.setFeedbackBar();
            this.setTables();
            
            this.sendActiveCampaignEvent('article_read', {
                'eventdata': this.getArticleId()+'_'+this.getArticleTitle().substring(0, 10)
            });

            if (this.articlePersonas.length > 0) {
                for (var i = 0; i < this.articlePersonas.length; i++) {
                    this.sendActiveCampaignEvent('article_read_pers_'+this.articlePersonas[i], {
                        'eventdata': this.getArticleId()+'_'+this.getArticleTitle().substring(0, 10)
                    });     
                }
            }
        },
        setElements: function() {
            this.shareContent = $('[data-purpose="share-content"]');
            this.feedbackBar = $('[data-purpose="scroll-feedback-bar"]');
            this.ratingContent = this.container.find('[data-purpose="rating-content"]');
            if (this.ratingContent && this.ratingContent.length > 0) {
                this.ratingSelect = this.ratingContent.find('select');
            }
            
            this.voteBox = this.container.find('[data-purpose="vote-box"]');
            this.bottomBorder = this.container.find('[data-purpose="scroll-feedback-border"]');
            
            $('[data-purpose="blog-subscription-form"]').each(function(){
                new BlogSubscribeForm($(this));
            });

            try {
                var rated = localStorage.getItem('rated_articles');
                var voted = localStorage.getItem('votes_after_article');
                if (rated) {
                    this.ratedArticles = JSON.stringify(rated);
                    if (this.ratedArticles.indexOf(this.getArticleId()) >= 0) {
                        this.rated = true;
                    }
                }

                if (voted) {
                    this.voteAfterArticles = JSON.stringify(voted);
                    if (this.voteAfterArticles.indexOf(this.getArticleId()) >= 0) {
                        this.voted = true;
                        this.voteBox.find('[data-purpose="button-row"]').addClass('voted');
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        addDOMEvents: function() {
            var _self = this;
                                   
            this.windowWidth = $(window).width();
            var scrollFn = function() {
                _self.setFeedbackBar();
                _self.setShareContent();
            };

            var resizeFn = function() {
                if (_self.windowWidth !== $(window).width()) {
                    _self.windowWidth = $(window).width();
                    _self.setFeedbackBar();
                }
            }

            if (!this.voted && this.voteBox) {
                this.voteBox.find('[data-purpose="vote-btn"]').click(function(){
                    if (_self.voteAfterArticles.indexOf(_self.getArticleId()) >= 0 || _self.voted) {
                        return false;
                    }
                    
                    _self.sendQuestionVote($(this));
                });
            }

            window.addEventListener('scroll', scrollFn);

			window.addEventListener('touchend', scrollFn);
			window.addEventListener('touchmove', scrollFn);
            window.addEventListener('resize', resizeFn);
        },
        setShareContent: function() {
            if ($(window).width() > 767) {
                this.checkShareContentPosition();
            } else {
                this.checkShareContentMobilePosition();
            }
        },
        checkShareContentPosition: function() {
			var item = $('[data-purpose="share-content-border"]');
			var scrollTop = $(window).scrollTop();
			var topBorder = item.offset().top;
			var header = $('header.article-page .top');
			if ($('header.article-page .mobile-header').is(':visible')) {
				header = $('header.article-page .mobile-header');
			}
			var headerHeight = header.outerHeight();
            
			if (scrollTop + headerHeight >= topBorder && !this.shareContent.hasClass('fixed')) {
				this.shareContent.addClass('fixed');
			} else if (topBorder > scrollTop + headerHeight && this.shareContent.hasClass('fixed')) {
				this.shareContent.removeClass('fixed');
			}
        },
        checkShareContentMobilePosition: function() {
			var scrollTop = $(window).scrollTop();
			var windowHeight = $(window).height();
			var header = $('header.article-page .top');
			if ($('header.article-page .mobile-header').is(':visible')) {
				header = $('header.article-page .mobile-header');
			}
			var item = $('main.blog-page');
			var bottomBorder = item.offset().top + item.outerHeight(true) + header.outerHeight(true);

			if (scrollTop + windowHeight >= bottomBorder && !this.shareContent.hasClass('hidden')) {
				this.shareContent.addClass('hidden');
			} else if (scrollTop + windowHeight < bottomBorder && this.shareContent.hasClass('hidden')) {
				this.shareContent.removeClass('hidden');
			}
		},
        setRatingBar: function() {
            var _self = this;
            if (this.ratingSelect && this.ratingSelect.length > 0) {
                var ratingText = this.ratingContent.find('.rating-text');
                
                ratingText.find('span').html(this.rate_count);
                if (ratingText.hasClass('hidden') && this.rate_count > 0) {
                    ratingText.removeClass('hidden');
                }
                var currentRating = this.getRateRating();
                if (isNaN(currentRating)) {
                    currentRating = 5;
                }
                this.ratingSelect.barrating({
                    theme: 'fontawesome-stars-o',
                    initialRating: currentRating,
                    showSelectedRating: false,
                    deselectable: false,
                    readonly: _self.hasRated(),
                    onSelect: function(value, text, evt) {
                        if (!evt) {
                            return false;
                        }
                        _self.rated = true;
                        
                        if (!value) {
                            _self.ratingSelect.barrating('clear');
                        } else {
                            _self.addToRateSum(value);
                            _self.rate_count++;
                            _self.ratingContent.find('.current-rating').addClass('hidden');
                            _self.ratingSelect.barrating('readonly', true);
                            
                            _self.ratingSelect.barrating('set', _self.getRateRating());
                            _self.ratingContent.find('.your-rating').removeClass('hidden').find('span').html(value);
                            
                            _self.ratingSelect.barrating('destroy');
                            _self.setRatingBar();

                            _self.sendRate(value);
                        }
                    },
                    onClear: function(value, text) {
                        _self.ratingContent.find('.current-rating')
                            .removeClass('hidden')
                            .end()
                            .find('.your-rating')
                            .addClass('hidden');
                    }
                });
            }
        },
        setFeedbackBar: function() {
            if (this.bottomBorder && this.bottomBorder.length == 1) {
                var height = this.bottomBorder.offset().top + this.bottomBorder.outerHeight();       
            } else {
                var height = this.container.height() + this.container.offset().top;
            }

            var windowHeight = $(window).height();
            var width = 0;
            if (windowHeight < height) {
                var scrollTop = $(window).scrollTop();
                var currentBottom = scrollTop+windowHeight;
                var maxBottom = height;
                if (currentBottom < maxBottom) {
                    width = currentBottom / maxBottom * 100; 
                } else {
                    width = 100;
                    if (!this.sendedEndScrollEvt) {
                        this.sendedEndScrollEvt = true;
                        this.sendActiveCampaignEvent('blog_endscroll', {
                            'eventdata': this.getArticleId()+'_'+this.getArticleTitle().substring(0, 10)
                        });
                    }
                }

                if (width >= 50) {
                    this.openSubscribePopup();
                }
            }

            this.feedbackBar.find('.bg-content').width(width+"%");
        },
        openSubscribePopup: function() {
            var item = $('[data-purpose="subscribe-remodal"]');
            if (window.innerWidth <= this.popupSubscribeBreakpoint && item.length > 0) {
                var viewed = true;
                if (typeof sessionStorage !== 'undefined') {
                    viewed = sessionStorage.getItem('viewed_subscribe_form');
                }

                if (!viewed && !item.hasClass('has-opened')) {
                    item.remodal().open();
                    item.addClass('has-opened');
                    
                    if (typeof sessionStorage !== 'undefined') {
                        sessionStorage.setItem('viewed_subscribe_form', 1);
                    }
                }
            }
        },
        setParams: function(params) {
            if (params.rate_sum) this.setRateSum(params.rate_sum);
            if (params.rate_count) this.setRateCount(params.rate_count);
            if (params.article_id) this.setArticleId(params.article_id);
            if (params.article_title) this.setArticleTitle(params.article_title);
            if (params.article_personas) this.setArticlePersonas(params.article_personas);
        },
        setRateSum: function(sum) {
            this.rate_sum = sum;
        },
        setRateCount: function(count) {
            this.rate_count = count;
        },
        setArticleId: function(article_id) {
            this.article_id = article_id;
        },
        setArticleTitle: function(title) {
            this.article_title = title;
        },
        setArticlePersonas: function(personas) {
            this.articlePersonas = personas;
        },
        hasRated: function() {
            return this.rated;
        },
        addToRateSum: function(value) {
            this.rate_sum += parseInt(value);
        },
        getRateRating: function() {
            return parseFloat(this.rate_sum / this.rate_count);
        },
        getArticleId: function() {
            return this.article_id;
        },
        getArticleTitle: function() {
            return this.article_title;
        },
        sendRate: function(value) {
            var _self = this;
            
            $.ajax({
                url:ajax_controller+'/blog_rate',
                dataType:'json',
                type:'post',
                data: {
                    value: value,
                    article_id: _self.getArticleId(),
                    article_title: _self.getArticleTitle()
                },
                success: function(response) {
                    if (response.success) {
                        try {
                            var rated = localStorage.getItem('rated_articles');
                            if (rated) {
                                rated = JSON.parse(rated);
                            } else {
                                rated = [];
                            }
                            
                            if (rated.indexOf(_self.getArticleId()) < 0) {
                                rated.push(_self.getArticleId());
                                localStorage.setItem('rated_articles', JSON.stringify(rated));
                            }
                        } catch (error) {
                            console.error(error);
                        }
                            
                    }
                }
            });
        },
        sendQuestionVote: function(btn) {
            var _self = this;
            
            if (!this.sendedVote && this.voteBox) {
                this.sendedVote = true;
                var question_id = this.voteBox.data('question-id');
                var answer_id = btn.data('answer-id');
                $.ajax({
                    url:ajax_controller+'/question_vote',
                    dataType:'json',
                    type:'post',
                    data: {
                        question_id: question_id,
                        answer_id: answer_id,
                        article_id: _self.getArticleId(),
                        article_title: _self.getArticleTitle()
                    },
                    success: function(response) {
                        if (response.success) {
                            try {
                                var voted = localStorage.getItem('votes_after_article');
                                if (voted) {
                                    voted = JSON.parse(voted);
                                } else {
                                    voted = [];
                                }
                                
                                if (voted.indexOf(_self.getArticleId()) < 0) {
                                    voted.push(_self.getArticleId());
                                    localStorage.setItem('votes_after_article', JSON.stringify(voted));
                                }
                            } catch (error) {
                                console.error(error);
                            }

                            _self.setQuestionBoxContent(response.results);
                        }
                    }
                });  
            }
        },
        setQuestionBoxContent: function(answers) {
            this.voteBox.find('[data-purpose="button-row"]').addClass('voted');
            this.voteBox.find('[data-purpose="vote-btn"]').each(function(){
                var btn = $(this);
                var value = typeof answers[btn.data('answer-id')] !== 'undefined' ? answers[btn.data('answer-id')] : '0%';
                                
                btn.find('.bg-content .inner').width(value);
                btn.find('.value').html(value);
            });
        },
        setTables: function() {
            this.container.find('article table').each(function(){
                var row = $('<div>', {
                    class:'table-outer'
                });

                $(this).clone().appendTo(row);
                row.insertBefore($(this));
                $(this).remove();
            });
        },
        sendActiveCampaignEvent: function(evt_name, data) {
            if (typeof ActiveCampaign !== 'undefined') {
                ActiveCampaign.trackEvent(evt_name, data);
            }
        }
    }
    window.BlogPage = BlogPage;
})(window);
