(function(wnd){
    function SearchBox(form, options) {
        try {
            if (!form) {
                throw 'Form is required!';
            }

            this.form = form

            if (options) {
                this.setParams(options);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    SearchBox.prototype = {
        form: undefined,
        input: undefined,
        button: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.input = this.form.find('input');
            this.button = this.form.find('[data-purpose="send-button"]');
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.input) {
                this.input.keydown(function(e){
                    var value = $(this).val();
                    if (e.keyCode == 13 && (value.trim()).length > 0) {
                        e.preventDefault();
                        _self.sendForm();
                    }
                });
            }

            if (this.button) {
                this.button.click(function(){
                    var value = _self.input.val();
                    if ((value.trim()).length > 0) {
                        _self.sendForm();
                    }
                });
            }
        },
        setParams: function(params) {
            console.log(params);
        },
        sendForm: function() {
            var url = this.form.attr('action');
            url += '?search='+encodeURI(encodeURIComponent(this.input.val()));
            
            document.location.href = url;
        }
    }

    wnd.SearchBox = SearchBox;
})(window);
